/* loading animation */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
/* end loading animation */

/* download button */
.download-button {
  box-shadow: inset 0px -3px 7px 0px #0086fe;
  background: #0086fe;
  background-color: #0086fe;
  border-radius: 7px;
  border: 1px solid;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
  padding: 5px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
}
.download-button:hover {
  background: linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
  background-color: #0688fa;
}
.download-button:active {
  position: relative;
  top: 1px;
}
/* end download button */

/* submit button */
.submit-button {
  background: #ffb700;
  background-color: #ffb700;
  border-radius: 7px;
  border: 0.1px solid;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  font-size: 15px;
  padding: 5px 15px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
}
.submit-button:hover {
  background: linear-gradient(to bottom, #ffb700 5%, #da9e07 100%);
  background-color: #ffb700;
}
.submit-button:active {
  position: relative;
  top: 1px;
}
/* submit button */
